import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithTextImage.js";
import MainFeature2 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import Img1 from "images/a4h1.png";
import Img2 from "images/a4h3.png";
import Img3 from "images/a4h4.png";
import Img4 from "images/a4h5.png";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>The Problem</Subheading>}
        heading="Need for voice automation in hospitality industry."
        description="Improving customer experience and customer engagement while the guests enjoy their stay is critical to any establishment in the hospitality industry. Avoiding wait times for service delivery or lack of necessary tools to provide guests with information when they need it, can lead to customer dissatisfaction and affects customer loyalty. Additionally, delighting customers with innovative solutions with a natural interface that are alway ready to enhance customer experience and making their stay more memorable can lead to improved customer loyalty. Guests and patrons expect similar or even better smart home features than what they are used to, at a place where they expect to relax, unwind and be taken care of."
        imageSrc={Img1}
      />

      <MainFeature1
        subheading={<Subheading>The Solution</Subheading>}
        heading="Voice Concierge."
        description="Aqua Metal Systems provides a full service solution for property owners and operators with integrated voice applications and voice assistants.  These solutions enable property owners and managers to administer voice based devices on their properties with ease. Their customers get to enjoy top notch customer service that is pleasing, personal and always accessible."
        imageSrc={Img2}
        textOnLeft={false}
      />

      <MainFeature1
        subheading={<Subheading>Aqua Metal</Subheading>}
        heading="Full Service. Hassle Free Solution."
        description="Frictionless solutions with natural human interface that don’t need specific knowledge or prior experience to work with make the seamless introduction and integration of new technology possible. Built on top of Alexa for Hospitality as the foundational platform, Aqua Metal’s solutions can be used to deploy and manage voice based solutions in properties such as hotels, resorts, vacation rental communities, hospitals, and senior living communities."
        imageSrc={Img3}
      />

      <MainFeature2
        subheading={<Subheading>Fully Integrated</Subheading>}
        heading="Simplified fleet management for properties owners."
        description="Aqua Metal’s applications are customized to provide the personal touch and delightful customer experience like room service, amenities information, events and promotions, and concierge services. Additionally, the smart home features allow the property owners and operators to further enhance the customer experience by offering voice based control for room lights, TVs, thermostats, blinds etc.,"
        imageSrc={Img4}
        textOnLeft={false}
        buttonRounded={false}
        primaryButtonUrl="/components/innerPages/ContactUsPage"
        primaryButtonText="Contact Us"
      />
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";


import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About AquaMetal</Subheading>}
        heading="We are a modern technology agency."
        description="Our expertise include application development, Cloud deployments, support outsourcing, Voice enablement and software consultancy. We have worked with startups to large corporations with personalized attention to successfully launch great products."
        buttonRounded={false}
        primaryButtonUrl="/components/innerPages/ContactUsPage"
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the technology space."
        description="Out latest innovations harness the power of Cloud, Voice and AI/ML to deliver the next generation of ground-breaking solutions for our customers. We deliver full-service solution from design to deplyment and support, at a competitive price to our customers."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/components/innerPages/ContactUsPage"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We stand by our customers."
        description="From prototyping, pilot launch and full scale deploymnet, we are there with you every step of the way. We provide comprehensive post-sales support for all installations."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "With multiple support channels, our customers can reach us for critical support needs."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Our business and technical teams are experienced and skilled in all aspects of the product support."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "We stand behind our product and services. Our goal is to provide complete customer satisfaction and resolution."
          },
        ]}
        linkText=""
      />
      
      <Footer />
    </AnimationRevealPage>
  );
};

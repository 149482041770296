const config = {
    s3: {
      REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://21ujwaxqig.execute-api.us-east-1.amazonaws.com/prod/",
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_od1kIpMJK",
      APP_CLIENT_ID: "2lrfnvj29un7phehofkmjn36ce",
      IDENTITY_POOL_ID: "us-east-1:f1673442-c87f-4fec-80b4-0f0e83506ba7",
    },
    social: {
      FB: "816730592226012"
    }
  };
  
  export default config;